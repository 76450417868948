<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-form-group
        label="Video Bağlantısı"
        label-for="videoembed"
      >
        <b-form-input
          id="videoembed"
          v-model="videoUrl"
          placeholder="Video Bağlantısı"
          @input="getEmbedCode"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
    >
      <b-form-group
        label="Video"
        label-for="video_embed"
      >
        <b-embed
          v-if="itemData.videoembed"
          id="video_embed"
          type="iframe"
          aspect="16by9"
          :src="youtubeUrl + itemData.videoembed"
        />
        <div
          v-else
          class="bg-light py-5 rounded text-muted text-center"
        >
          <div class="my-2">
            <div>
              <FeatherIcon icon="VideoIcon" />
            </div>
            <div>
              Video Önizleme
            </div>
          </div>
        </div>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>

import {
  BCol, BFormGroup, BFormInput, BEmbed, BRow,
} from 'bootstrap-vue'

export default {
  name: 'VideoEmbed',
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BEmbed,
    BRow,
  },
  data() {
    return {
      videoUrl: null,
      youtubeUrl: 'https://www.youtube.com/embed/',
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['press/dataItem']
    },

  },
  methods: {
    getEmbedCode() {
      this.$store.dispatch('videoGallery/getEmbedCode', this.videoUrl)
        .then(res => {
          this.itemData.videoembed = res.id
        })
    },
  },
}
</script>
